

export const UYA_BACKGROUND_IMAGES: string[] = [
    "https://rac-horizon-resources.s3.amazonaws.com/backgrounds/UYABackground.jpg",
    "https://rac-horizon-resources.s3.amazonaws.com/backgrounds/UYABackground2.jpg",
    "https://rac-horizon-resources.s3.amazonaws.com/backgrounds/UYABackground3.jpg",
    "https://rac-horizon-resources.s3.amazonaws.com/backgrounds/UYABackground4.jpg",
    "https://rac-horizon-resources.s3.amazonaws.com/backgrounds/UYABackground5.jpg",
    "https://rac-horizon-resources.s3.amazonaws.com/backgrounds/UYABackground6.jpg",
    "https://rac-horizon-resources.s3.amazonaws.com/backgrounds/UYABackground7.jpg",
    "https://rac-horizon-resources.s3.amazonaws.com/backgrounds/UYABackground8.jpg",
    "https://rac-horizon-resources.s3.amazonaws.com/backgrounds/UYABackground9.jpg",
    "https://rac-horizon-resources.s3.amazonaws.com/backgrounds/UYABackground10.jpg",
]